import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layouts';
import Hero from '../components/shared/Hero';
import InitiativesSection from '../components/services/InitiativesSection';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';


class ServicesPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("What We Do", {
      title: `What We Do | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const {data} = this.props;
    const pageData = data.prismicServicesPage.data;

    return (
      <Layout reverseLogo>
        <Helmet>
          {titleTags(`What We Do | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.hero_brief.text)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          title={pageData.hero_title}
          message={pageData.hero_message}
          brief={pageData.hero_brief.html}
          image={pageData.hero_image}
        />
        <InitiativesSection pageData={pageData}/>
      </Layout>
    );
  }
}


export default ServicesPage;

export const query = graphql`
  query ServicesQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicServicesPage {
      dataString
      data {
        hero_title
        hero_message
        hero_brief {
          html
          text
        }
        hero_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        initiatives_title
        initiatives_message
        initiatives_quote
        initiatives {
          service_page {
            id
            uid
          }
          title
          brief {
            html
            text
          }
          image {
            alt
            copyright
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1920, quality: 90, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        organisations_title
        organisations_message
      }
    }
  }
`;
